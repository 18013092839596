import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import routes from "../../shared/routes";

import { useLocation } from "react-router-dom";

import qatrace from "../../euro-logo.jpeg";
import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideNavigationBar(props) {
  const { outsideClickRef, user_type } = props;

  const [isMinimized, setIsMinimized] = useState(false);

  const location = useLocation();
  const getRoutes = routes(location.pathname, parseInt(user_type));
  const routesArray = getRoutes["routes"];

  return (
    <div
      className="layout-sidebar hide-for-print bg-main-purple"
      ref={outsideClickRef}
    >
      {/* Static sidebar for desktop */}
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pr-0 sm:pr-6 pb-4">
        <div className="flex h-16 shrink-0 items-center mt-2 my-2">
          <img
            className={"mt-10 h-20 w-auto py-2"}
            src={qatrace}
            alt="Your Company"
          />
        </div>
        <nav className="flex flex-1 flex-col mt-5 layout-menu-container">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="space-y-1">
                {routesArray.map((item) => (
                  <li key={item.name}>
                    {!item.children ? (
                      <a
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "text-black font-semibold"
                            : "text-color hover:bg-gray-100",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 "
                        )}
                      >
                        <item.icon
                          className="h-5 w-5 shrink-0 main-purple"
                          aria-hidden="true"
                        />
                        <p className={isMinimized ? "hidden" : null}>
                          {item.name}
                        </p>
                      </a>
                    ) : (
                      <Disclosure
                        as="div"
                        defaultOpen={item.open}
                        className="sidebar-parent-div"
                      >
                        {/* Button click starts */}
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                item.current
                                  ? "text-black font-semibold"
                                  : "text-color hover:bg-gray-100",
                                "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6"
                              )}
                              onClick={() => setIsMinimized(false)}
                            >
                              <item.icon
                                className="h-5 w-5 shrink-0 main-purple"
                                aria-hidden="true"
                              />
                              <div className={"flex w-full"}>
                                {item.name}
                                {open ? (
                                  <ChevronDownIcon
                                    className="ml-auto h-5 w-5 shrink-0 text-color"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronRightIcon
                                    className="ml-auto h-5 w-5 shrink-0 text-color"
                                    aria-hidden="true"
                                  />
                                )}
                              </div>
                            </Disclosure.Button>
                            <Transition
                              enter="duration-500 ease-out"
                              enterFrom="opacity-0 -translate-y-6"
                              enterTo="opacity-100 translate-y-0"
                              leave="duration-500 ease-out"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 -translate-y-6"
                            >
                              <Disclosure.Panel as="ul" className="mt-1 px-2">
                                {item.children.map((subItem) => (
                                  <li key={subItem.name}>
                                    <Disclosure.Button
                                      as="a"
                                      href={subItem.href}
                                      className={classNames(
                                        subItem.current
                                          ? "text-black font-semibold"
                                          : "text-color hover:bg-gray-100",
                                        "block rounded-md py-2 pr-2 pl-9 text-sm leading-6"
                                      )}
                                    >
                                      {subItem.name}
                                    </Disclosure.Button>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                        {/* Button click ends */}
                      </Disclosure>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
