import { classNames, formatDateStandard } from "../../../../shared/utility";

export default function PackagingProductLabel(props) {
  const { packagingLabel } = props;

  return (
    <div className="hidden label-section-to-print space-y-0">
      <div className="grid grid-cols-11">
        <div className="col-span-9">
          <div className="flex">
            <div className="ml-2 mb-1">
              <img
                src={packagingLabel.brand_image1}
                alt="Brand logo"
                width="525px"
                height="400px"
              />
              <p className="text-packaging-plu">{packagingLabel.product_plu}</p>
              <div className="pt-1 flex">
                <img
                  src={packagingLabel.brand_image2}
                  alt="Brand certification"
                  width="55px"
                  height="55px"
                />
                <img
                  src={packagingLabel.brand_image3}
                  alt="Brand certification"
                  width="55px"
                  height="55px"
                />
              </div>
            </div>
            <div className="mb-1">
              <div>
                <p className="line-height-10">
                  {packagingLabel.is_default_brand ? null : "Prepared for"}{" "}
                  {packagingLabel.brand_name}
                </p>
              </div>
              <div>
                <p className="text-md font-bold line-height-10">
                  {packagingLabel.product_name}
                </p>
              </div>
              <div>
                <p className="text-sm font-bold line-height-8">
                  {packagingLabel.product_description}
                </p>
              </div>
              <div className="">
                <p className="font-size-10 line-height-1 mr-4">
                  <span className="font-bold">Ingredients:</span>
                  {packagingLabel.ingredients}
                </p>
              </div>
              <div className="flex">
                <div>
                  <p className="text-lg font-bold line-height-10">
                    NET WT {packagingLabel.product_weight}
                  </p>
                  <p className="text-sm">TARE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {packagingLabel.product_barcode_html ? (
          <div className="py-2 flex col-span-2">
            <p
              className="text-barcode-slip"
              style={{ writingMode: "vertical-rl" }}
            >
              {packagingLabel.product_barcode}
            </p>
            <img
              src={
                "data:image/png;base64, " + packagingLabel.product_barcode_html
              }
              alt="Supplier item barcode"
            />

            <p
              className="text-barcode-slip"
              style={{ writingMode: "vertical-rl" }}
            >
              PRODUCT UPC
            </p>
          </div>
        ) : null}
      </div>
      <div>
        <div className="mx-5">
          <div className="flex space-x-4 w-full">
            <div>
              <p className="text-barcode-slip">Production Date</p>
              <p className="text-barcode-slip">Date de production</p>
              <p className="text-barcode-slip">
                {formatDateStandard(packagingLabel.production_date)}
              </p>
            </div>
            <div>
              <p className="text-barcode-slip">Best before</p>
              <p className="text-barcode-slip">Meilleure avant</p>
              <p className="text-barcode-slip">
                {packagingLabel.best_before_date}
              </p>
            </div>
            <div>
              <p
                className={classNames(
                  packagingLabel.passed_from_metal_detector
                    ? "text-barcode-slip font-bold"
                    : "hidden"
                )}
              >
                PASSED FROM METAL DETECTOR
              </p>
              <p className="text-barcode-slip font-bold">
                {packagingLabel.storage_description_en}
              </p>
              <p className="text-barcode-slip font-bold align-text-bottom">
                Lot #: {packagingLabel.product_lot}
              </p>
            </div>
          </div>
          <img
            src={"data:image/png;base64, " + packagingLabel.barcodeHtml}
            alt="Supplier item barcode"
          />
          <p className="text-barcode-slip text-center">
            {packagingLabel.barcode}
          </p>
          <div className="align-bottom">
            <p className="text-barcode-slip line-height-1">
              {packagingLabel.address}
            </p>
            <p className="text-barcode-slip">Designed by: QA Trace Inc.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
