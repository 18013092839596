import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import React from "react";

interface Stat {
  name: string;
  stat: string;
  previousStat: string;
  change: string;
  changeType: "increase" | "decrease";
}

interface Analytics {
  range: string;
  stats: Stat[];
  loading: Boolean;
}

interface StatsProps {
  analytics: Analytics;
}

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

const StatsComponents: React.FC<StatsProps> = ({ analytics }) => {
  return (
    <div>
      <h3 className="text-base font-semibold text-gray-900">
        {analytics.range}
      </h3>
      <dl className="mt-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-xl bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
        {analytics.stats.map((item) => (
          <div key={item.name} className="p-4">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline md:block lg:flex gap-2">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">
                  from {item.previousStat}
                </span>
              </div>

              {/* <div
                className={classNames(
                  item.changeType === "increase"
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800",
                  "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                )}
              >
                {item.changeType === "increase" ? (
                  <ArrowUpIcon
                    aria-hidden="true"
                    className="-ml-1 mr-0.5 size-5 shrink-0 self-center text-green-500"
                  />
                ) : (
                  <ArrowDownIcon
                    aria-hidden="true"
                    className="-ml-1 mr-0.5 size-5 shrink-0 self-center text-red-500"
                  />
                )}

                <span className="sr-only">
                  {item.changeType === "increase" ? "Increased" : "Decreased"}{" "}
                  by
                </span>
                {item.change}
              </div> */}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default StatsComponents;
